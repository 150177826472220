import './index.css';
// import '@fontsource/exo-2/latin.css';
// import '@fontsource/exo-2/latin-ext.css';
// import '@fontsource/exo-2/latin-italic.css';

import { registerSW } from 'virtual:pwa-register';

import i18n from 'i18next';
import HttpApi from 'i18next-http-backend';
import ReactDOM from 'react-dom/client';
import { initReactI18next } from 'react-i18next';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import * as Sentry from '@sentry/react';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import { LicenseInfo } from '@mui/x-license';

LicenseInfo.setLicenseKey(
  '6cada3a43e094da37217bed92f7ecd54Tz04MTU4MixFPTE3MzYwMjA0ODkwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
);

import App from './App.tsx';
import { NotificationProvider } from '@/contexts/NotificationCtx';

registerSW({ immediate: true });

if (ENVIRONMENT !== 'development') {
  Sentry.init({
    dsn: 'https://2059a9a107511d66dc29953f9b2b9f6a@o4507889544593408.ingest.de.sentry.io/4507901161898064',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.captureConsoleIntegration({ levels: ['error'] }),
      Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [/^https:\/\/pluto3-devel\.itrack\.hu/, /^https:\/\/pluto3-devel\.itrack\.hu\/api/],

    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const params = new URLSearchParams(document.location.search);
const l = params.get('l');

// TODO meg a browser language-et is hasznalhatnank
// TODO miert 'hu' ha nincs mas es 'en' a default ?
const language = l || localStorage.getItem('language') || 'hu';
const defaultLanguage = 'en';

i18n
  // FIXME .use(LanguageDetector)
  .use(HttpApi)
  .use(initReactI18next)
  .init({
    lng: language,
    load: 'languageOnly',
    backend: {
      loadPath: '/i18n/{{lng}}.json',
    },
    fallbackLng: defaultLanguage,
    interpolation: {
      escapeValue: true,
    },
  });

const queryClient = new QueryClient();

const theme = createTheme({
  cssVariables: true,
  // colorSchemes: { light: true, dark: true },
  typography: {
    allVariants: {
      fontFamily: "'Exo 2'",
    },
  },
});

ReactDOM.createRoot(
  document.getElementById('root')! /*, {
  onUncaughtError: (error, errorInfo) => {
    console.log(error, errorInfo);
  },
  onCaughtError: (error, errorInfo) => {
    console.log(error, errorInfo);
  },
}*/
).render(
  // <React.StrictMode>
  <QueryClientProvider client={queryClient}>
    <ThemeProvider theme={theme}>
      <CssBaseline enableColorScheme />
      <NotificationProvider>
        <App />
      </NotificationProvider>
    </ThemeProvider>
  </QueryClientProvider>
  // </React.StrictMode>
);
